<template>
    <div>
        <!-- 배너 -->
        <LocationBanner />

        <!-- 본문 -->
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
            style="background:#f4f4f4;"
        >
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 1080:'100%'"
                class="mx-auto"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
                style="background:#f4f4f4;"
            >
                <p
                    class="text-h5 font-weight-bold primary--text"
                    style="letter-spacing:1px !important;"
                >
                    오시는 길
                </p>
                <v-divider></v-divider>

                <div
                    class="mt-4"
                >
                    <v-sheet
                        data-aos="fade-up" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        style="background:#f4f4f4;"
                    >
                        <!-- <v-img
                            src="@/assets/location/map.png"
                        ></v-img> -->

                        <naver-maps
                            :height="$vuetify.breakpoint.mobile? 400:800"
                            :width="width"
                            :mapOptions="mapOptions"
                            :initLayers="initLayers"
                            @load="onLoad"
                        >
                            <naver-marker
                                :lat="37.492"
                                :lng="127.0097"
                            ></naver-marker>
                        </naver-maps>

                        <p
                            class="font-weight-bold mt-6 pl-2 mb-2"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                        >
                            <v-icon color="secondary" class="mr-1 pb-2px">mdi-map-marker-outline</v-icon>위치 안내
                        </p>

                        <div
                            class="pa-3 pt-1"
                            style="width:100%; position:relative;"
                        >
                            <p
                                v-if="!$vuetify.breakpoint.mobile"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                            >
                                <font class="secondary--text mr-2 font-weight-bold">주소 : </font>서울 서초구 서초대로 254 9층 912,913호 <br v-if="$vuetify.breakpoint.mobile"/>(서초동, 오퓨런스 빌딩) 우.06647<br/>
                                <font class="secondary--text mr-2 font-weight-bold">문의전화 : </font>02-3476-5016,7<br/>
                                <font class="secondary--text mr-2 font-weight-bold">방문 가능시간 : </font>월~금 09:00 ~ 18:00 <br v-if="$vuetify.breakpoint.mobile"/>(12:00 ~ 13:00 점심시간)<br/>
                            </p>

                            <p
                                v-if="$vuetify.breakpoint.mobile"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                                style="line-height:1.8em"
                            >
                                <font class="secondary--text mr-2 font-weight-bold">
                                    주소 : </font>서울 서초구 서초대로 254 9층 912,913호
                                    <br v-if="$vuetify.breakpoint.mobile"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(서초동, 오퓨런스 빌딩) 우.06647<br/>
                                <font class="secondary--text mr-2 font-weight-bold">문의전화 : </font>02-3476-5016,7<br/>
                                <font class="secondary--text mr-2 font-weight-bold">
                                    방문 가능시간 : </font>월~금 09:00 ~ 18:00 <br v-if="$vuetify.breakpoint.mobile"/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(12:00 ~ 13:00 점심시간)<br/>
                            </p>
                        </div>

                        <p
                            class="font-weight-bold mt-6 pl-2 mb-2"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                        >
                            <v-icon color="secondary" class="mr-1 pb-2px">mdi-train-bus</v-icon>대중교통 안내
                        </p>


                        <p
                            class="ml-3 mb-2 font-weight-bold text-subtitle-1 secondary--text"
                        >
                            지하철 안내
                        </p>

                        <div
                            class="pa-3 pt-0 pb-2 d-flex align-center"
                            style="width:100%; position:relative;"
                        >
                            <v-btn
                                depressed
                                class="rounded-20"
                                dark
                                small
                                color="#00A84D"
                            >
                                2호선
                            </v-btn>
                            <p
                                class="mb-0 ml-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                            >
                                서초역 1번출구 1분거리
                            </p>
                        </div>
                        <div
                            class="pa-3 pt-0 pb-2 d-flex align-center"
                            style="width:100%; position:relative;"
                        >
                            <v-btn
                                depressed
                                class="rounded-20"
                                dark
                                small
                                color="#00A84D"
                            >
                                2호선
                            </v-btn>
                            <v-btn
                                depressed
                                class="rounded-20 ml-1"
                                dark
                                small
                                color="#EF7C1C"
                            >
                                3호선
                            </v-btn>
                            <p
                                class="mb-0 ml-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                            >
                                교대역 9번출구 5분거리
                            </p>
                        </div>

                        <p
                            class="mt-4 ml-3 mb-2 font-weight-bold text-subtitle-1 secondary--text"
                        >
                            버스 안내
                        </p>

                        <div
                            class="pa-3 pt-0 pb-2 d-flex align-center"
                            style="width:100%; position:relative;"
                        >
                            <v-btn
                                depressed
                                class="rounded-20"
                                dark
                                small
                                color="#1e7bdb"
                            >
                                간선버스
                            </v-btn>
                            <p
                                class="mb-0 ml-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                            >
                                541, 740
                            </p>
                        </div>
                        <div
                            class="pa-3 pt-0 pb-2 d-flex align-top"
                            style="width:100%; position:relative;"
                        >
                            <v-btn
                                depressed
                                class="rounded-20"
                                dark
                                small
                                color="#e43506"
                            >
                                광역버스
                            </v-btn>
                            <p
                                class="mb-0 ml-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                            >
                                1006, 3000, 3003, 3100, 3101, 3102, 3200, 3300, 3400, 6008, 6501,<br/>
                                9100, 9200, 9201, 9300, M5443, M6405, M6410, M6439, 700, 700-1
                            </p>
                        </div>

                        <p
                            class="mt-2 ml-3 mb-2 font-weight-bold text-subtitle-1 secondary--text"
                        >
                            주차 가능
                        </p>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import LocationBanner from '@/components/LocationBanner'

export default {
    components: {
        LocationBanner,
    },

    data: () => ({
        width: '100%',
        info: false,
        marker: null,
        count: 1,
        map: null,
        isCTT: false,
        mapOptions: {
          lat: 37.492,
          lng: 127.01,
          zoom: 17,
          zoomControl: true,
          zoomControlOptions: {position: 'TOP_RIGHT'},
          mapTypeControl: true,
        },
        // initLayers: ['BACKGROUND', 'BACKGROUND_DETAIL', 'POI_KOREAN', 'TRANSIT', 'ENGLISH', 'CHINESE', 'JAPANESE']
    }),
}
</script>