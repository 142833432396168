import { render, staticRenderFns } from "./LawBanner.vue?vue&type=template&id=3ec20672&scoped=true"
var script = {}
import style0 from "./LawBanner.vue?vue&type=style&index=0&id=3ec20672&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec20672",
  null
  
)

export default component.exports