<template>
    <div>
        <!-- 배너 -->
        <LawBanner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
            style="background:#f4f4f4;"
        >
            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 1080:'100%'"
                class="mx-auto"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
                style="background:#f4f4f4;"
            >
                <p
                    class="text-h5 font-weight-bold primary--text"
                    style="letter-spacing:1px !important;"
                >
                    업무분야
                </p>
                <v-divider></v-divider>

                <!-- PC -->
                <div
                    v-if="!$vuetify.breakpoint.mobile"
                    class="mt-4"
                >
                    <div
                        :class="detail_type == ''? 'd-flex justify-space-between':''"
                    >
                        <v-sheet
                            v-if="detail_type == '부동산' || detail_type == ''"
                            :width="detail_type == '부동산'? '':'248'"
                            :height="detail_type == '부동산'? '560':'460'"
                            style="box-shadow:6px 6px 4px 2px #dedede; cursor:pointer; position:relative;"
                            :style="detail_type == '부동산'? 'background-position:0% 40%;':''"
                            class="background1 mb-6"
                            data-aos="fade-up" 
                            data-aos-delay="300" 
                            data-aos-easing="ease"
                            @click="toggleDetail('부동산')"
                        >
                            <v-sheet
                                :width="detail_type == '부동산'? '94%':'210'"
                                :height="detail_type == '부동산'? '480':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    부동산
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 오랜 기간 부동산 관련 소송을 진행해 오면서
                                    어렵고 복잡한 부동산 소송관계를 해결하는 전문적인 능력을 갖춘 변호사들로 구성되어 있습니다.
                                </p>

                                <div
                                    v-if="detail_type == '부동산'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        부동산 법률분쟁은 단순한 채권관계의 문제에서 그치는 것이 아니라 물권관계의 분쟁까지 이어져
                                        소유권, 등기말소, 용익물권 등 다양한 분야의 지식을 필요로 하며, 나아가 재건축 등
                                        국가의 정책과 밀접한 관련이 있는 분야이기에 행정적 지식까지 충분히 갖추고 대비할 필요가 있습니다.
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        이러한 이유로 법무법인 유승은 다양한 케이스에서 확보한 노하우들을 공유하며 부동산 분쟁에 있어
                                        최적의 서비스를 제공하고, 의뢰인이 원하시는 결과를 만들어 내기 위해 최적의 법률서비스를 제공하고 있습니다.
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 부동산 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0">- 아파트 하자에 따른 손해배상 및 하자보수보증금 청구 소송</p>
                                    <p class="mb-0">- 토지수용에 따른 보상금 증액을 위한 소송</p>
                                    <p class="mb-0">- 재개발, 재건축에 따른 법률자문</p>
                                    <p class="mb-0">- 건축물 공사 중 발생하는 소송(공사대금청구, 일조권 또는 조망권 침해소송 등)</p>
                                    <p class="mb-0">- 임대차에 따른 보증금 분쟁 및 명도소송</p>
                                    <p class="mb-0">- 지역주택조합에 대한 법률자문</p>
                                </div>
                            </v-sheet>

                            <div
                                style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                            >
                                <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                    {{detail_type == '부동산'? '접기':'더보기'}}
                                    <v-icon v-if="detail_type != '부동산'" color="primary" class="pb-3px">mdi-chevron-right</v-icon>
                                    <v-icon v-if="detail_type == '부동산'" color="primary" class="pb-3px">mdi-chevron-left</v-icon>
                                </p>
                            </div>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '민사' || detail_type == ''"
                            :width="detail_type == '민사'? '':'248'"
                            :height="detail_type == '민사'? '560':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            class="background2 mb-6"
                            data-aos="fade-up" 
                            data-aos-delay="500" 
                            data-aos-easing="ease"
                            @click="toggleDetail('민사')"
                        >
                            <v-sheet
                                :width="detail_type == '민사'? '94%':'210'"
                                :height="detail_type == '민사'? '480':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    민사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 수많은 민사소송 수행한 변호사들이 주축이 되어
                                    다양한 민사분쟁의 해결책을 찾아 의뢰인이 원하는 결과를 만들어 내는 법인입니다.
                                </p>

                                <div
                                    v-if="detail_type == '민사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        민사소송에 있어 단순히 재판에서 승소하는 것보다 중요한 것은 재산적인 손해를 현실적으로 회복하는 절차입니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 민사소송에 있어 가압류 및 가처분부터 본안소송을 거쳐 최종적인 강제집행 절차까지
                                        꼼꼼하게 수행하여 의뢰인이 만족할 수 있는 결과를 만들어내기 위해 최선을 다하고 있습니다.  
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 민사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0">- 각종 손해배상청구 소송 및 위자료청구소송</p>
                                    <p class="mb-0">- 가압류 가처분 등 보전처분의 수행 및 자문</p>
                                    <p class="mb-0">- 회사법상 주주총회, 이사회 등과 관련된 분쟁의 해결</p>
                                    <p class="mb-0">- 보험금청구, 대여금청구, 투자금 반환청구 등 일반적 금전 지급 청구소송</p>
                                    <p class="mb-0">- 사해해위 취소소송을 통한 채무자 재산확보</p>
                                    <p class="mb-0">- 강제집행신청, 집행정지 등 집행절차의 자문 및 대리수행</p>
                                </div>
                            </v-sheet>

                            <div
                                style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                            >
                                <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                    {{detail_type == '민사'? '접기':'더보기'}}
                                    <v-icon v-if="detail_type != '민사'" color="primary" class="pb-3px">mdi-chevron-right</v-icon>
                                    <v-icon v-if="detail_type == '민사'" color="primary" class="pb-3px">mdi-chevron-left</v-icon>
                                </p>
                            </div>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '형사' || detail_type == ''"
                            :width="detail_type == '형사'? '':'248'"
                            :height="detail_type == '형사'? '560':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            :style="detail_type == '형사'? 'background-position:0% 35%;':''"
                            class="background3 mb-6"
                            data-aos="fade-up" 
                            data-aos-delay="700" 
                            data-aos-easing="ease"
                            @click="toggleDetail('형사')"
                        >
                            <v-sheet
                                :width="detail_type == '형사'? '94%':'210'"
                                :height="detail_type == '형사'? '480':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    형사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 형사절차에서 차별화된 서비스를 제공하기 위해
                                    최적의 시스템을 갖춘 법인입니다. 
                                </p>

                                <div
                                    v-if="detail_type == '형사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        법무법인 유승의 변호사들은 체포 및 압수 단계의 법률대응부터 구속영장 발부에 대한
                                        대응, 구속적부심사, 보석 및 공판단계의 대응까지 빈틈없는 업무능력을 갖추고 있습니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        특히 체포 후 구속영장 발부 및 공소장 제출까지의 약 한달 가량의 시간동안 빠르게 진행되는 형사절차에서
                                        의뢰인이 적절히 대응하지 못하여 억울한 결과를 받아 드는 일이 없도록 법무법인 유승은 신속하고
                                        적절한 방식으로 의뢰인의 곁을 지키고 변호를 이어나가고 있습니다.
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 형사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0">- 경제범죄, 유사수신, 증권 및 암호화폐 등과 관련된 형사 변호 및 자문</p>
                                    <p class="mb-0">- 재건축 재개발 조합 구성과 관련하여 발생하는 형사분쟁에 대한 변호 및 자문</p>
                                    <p class="mb-0">- 각종 형사사건의 고소대리</p>
                                    <p class="mb-0">- 체포 구속에 대한 대응(영장실질심사, 구속적부심, 보석 등)</p>
                                    <p class="mb-0">- 교통사고, 음주운전 등 운전 중 발생할 수 있는 형사절차에 대한 변호 및 자문</p>
                                    <p class="mb-0">- 성범죄(고소대리, 합의대리, 성범죄 사건에 대한 변호 및 자문)</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '형사'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '형사'" color="primary" class="pb-3px">mdi-chevron-right</v-icon>
                                        <v-icon v-if="detail_type == '형사'" color="primary" class="pb-3px">mdi-chevron-left</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '상속/가사' || detail_type == ''"
                            :width="detail_type == '상속/가사'? '':'248'"
                            :height="detail_type == '상속/가사'? '560':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            :style="detail_type == '상속/가사'? 'background-position:0% 35%;':''"
                            class="background4 mb-6"
                            data-aos="fade-up" 
                            data-aos-delay="900" 
                            data-aos-easing="ease"
                            @click="toggleDetail('상속/가사')"
                        >
                            <v-sheet
                                :width="detail_type == '상속/가사'? '94%':'210'"
                                :height="detail_type == '상속/가사'? '480':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    상속/가사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 피상속인의 사망으로 인하여 발생할 수 있는
                                    상속관계에 대해 구체적인 해결방안을 찾고 의뢰인이
                                    자신의 상속분을 적절히 찾을 수 있도록 최선의 노력을 다하고 있습니다.
                                </p>

                                <div
                                    v-if="detail_type == '상속/가사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        특히 상속과 관련하여 유언단계에서부터 상속이 발생하는 때까지 빈틈없는 자문을 진행하여
                                        상속으로 인한 법률분쟁을 미연에 방지하는 역할도 수행하고 있습니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        가사분쟁과 관련하여는 협의이혼, 재판상이혼, 위자료청구 및 재산분할, 양육권 등의 법률분쟁들을
                                        각 분야의 특징에 맞는 대응으로 의뢰인이 원하시는 최적의 결과를 만들어 내고 있습니다. 
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 상속 및 가사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0">- 유언서 작성 및 유언집행</p>
                                    <p class="mb-0">- 상속재산분할, 유류분반환청구 및 기여분 등 상속관련 분쟁해결</p>
                                    <p class="mb-0">- 이혼, 사실혼 해소에 따른 법률분쟁해결</p>
                                    <p class="mb-0">- 친권, 양육권소송 및 양육비청구소송</p>
                                    <p class="mb-0">- 친생자 관련 소송</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '상속/가사'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '상속/가사'" color="primary" class="pb-3px">mdi-chevron-right</v-icon>
                                        <v-icon v-if="detail_type == '상속/가사'" color="primary" class="pb-3px">mdi-chevron-left</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>
                    </div>
                </div>

                <!-- Mobile -->
                <div
                    v-if="$vuetify.breakpoint.mobile"
                    class="mt-4"
                >
                    <div
                        :class="detail_type == ''? '':''"
                    >
                        <v-sheet
                            v-if="detail_type == '부동산' || detail_type == ''"
                            :width="detail_type == '부동산'? '':''"
                            :height="detail_type == '부동산'? '':'460'"
                            style="box-shadow:6px 6px 4px 2px #dedede; cursor:pointer; position:relative;"
                            class="background1 mb-6 pb-10"
                            data-aos="fade-up" 
                            data-aos-delay="300" 
                            data-aos-easing="ease"
                            @click="toggleDetail('부동산')"
                        >
                            <v-sheet
                                :width="detail_type == '부동산'? '94%':'80%'"
                                :height="detail_type == '부동산'? '':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    부동산
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 오랜 기간 부동산 관련 소송을 진행해 오면서
                                    어렵고 복잡한 부동산 소송관계를 해결하는 전문적인 능력을 갖춘 변호사들로 구성되어 있습니다.
                                </p>

                                <div
                                    v-if="detail_type == '부동산'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        부동산 법률분쟁은 단순한 채권관계의 문제에서 그치는 것이 아니라 물권관계의 분쟁까지 이어져
                                        소유권, 등기말소, 용익물권 등 다양한 분야의 지식을 필요로 하며, 나아가 재건축 등
                                        국가의 정책과 밀접한 관련이 있는 분야이기에 행정적 지식까지 충분히 갖추고 대비할 필요가 있습니다.
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        이러한 이유로 법무법인 유승은 다양한 케이스에서 확보한 노하우들을 공유하며 부동산 분쟁에 있어
                                        최적의 서비스를 제공하고, 의뢰인이 원하시는 결과를 만들어 내기 위해 최적의 법률서비스를 제공하고 있습니다.
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 부동산 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0 text-caption">- 아파트 하자에 따른 손해배상 및 하자보수보증금<br/>&nbsp;&nbsp;청구 소송</p>
                                    <p class="mb-0 text-caption">- 토지수용에 따른 보상금 증액을 위한 소송</p>
                                    <p class="mb-0 text-caption">- 재개발, 재건축에 따른 법률자문</p>
                                    <p class="mb-0 text-caption">- 건축물 공사 중 발생하는 소송(공사대금청구, 일조권<br/>&nbsp;&nbsp;또는 조망권 침해소송 등)</p>
                                    <p class="mb-0 text-caption">- 임대차에 따른 보증금 분쟁 및 명도소송</p>
                                    <p class="mb-0 text-caption">- 지역주택조합에 대한 법률자문</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '부동산'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '부동산'" color="primary" class="pb-3px">mdi-chevron-down</v-icon>
                                        <v-icon v-if="detail_type == '부동산'" color="primary" class="pb-3px">mdi-chevron-up</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '민사' || detail_type == ''"
                            :width="detail_type == '민사'? '':''"
                            :height="detail_type == '민사'? '':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            class="background2 mb-6 pb-10"
                            data-aos="fade-up" 
                            data-aos-delay="500" 
                            data-aos-easing="ease"
                            @click="toggleDetail('민사')"
                        >
                            <v-sheet
                                :width="detail_type == '민사'? '94%':'80%'"
                                :height="detail_type == '민사'? '':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    민사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 수많은 민사소송 수행한 변호사들이 주축이 되어
                                    다양한 민사분쟁의 해결책을 찾아 의뢰인이 원하는 결과를 만들어 내는 법인입니다.
                                </p>

                                <div
                                    v-if="detail_type == '민사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        민사소송에 있어 단순히 재판에서 승소하는 것보다 중요한 것은 재산적인 손해를 현실적으로 회복하는 절차입니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 민사소송에 있어 가압류 및 가처분부터 본안소송을 거쳐 최종적인 강제집행 절차까지
                                        꼼꼼하게 수행하여 의뢰인이 만족할 수 있는 결과를 만들어내기 위해 최선을 다하고 있습니다.  
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 민사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0 text-caption">- 각종 손해배상청구 소송 및 위자료청구소송</p>
                                    <p class="mb-0 text-caption">- 가압류 가처분 등 보전처분의 수행 및 자문</p>
                                    <p class="mb-0 text-caption">- 회사법상 주주총회, 이사회 등과 관련된 분쟁의 해결</p>
                                    <p class="mb-0 text-caption">- 보험금청구, 대여금청구, 투자금 반환청구 등 일반적<br/>&nbsp;&nbsp;금전 지급 청구소송</p>
                                    <p class="mb-0 text-caption">- 사해해위 취소소송을 통한 채무자 재산확보</p>
                                    <p class="mb-0 text-caption">- 강제집행신청, 집행정지 등 집행절차의 자문<br/>&nbsp;&nbsp;및 대리수행</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '민사'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '민사'" color="primary" class="pb-3px">mdi-chevron-down</v-icon>
                                        <v-icon v-if="detail_type == '민사'" color="primary" class="pb-3px">mdi-chevron-up</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '형사' || detail_type == ''"
                            :width="detail_type == '형사'? '':''"
                            :height="detail_type == '형사'? '':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            class="background3 mb-6 pb-10"
                            data-aos="fade-up" 
                            data-aos-delay="700" 
                            data-aos-easing="ease"
                            @click="toggleDetail('형사')"
                        >
                            <v-sheet
                                :width="detail_type == '형사'? '94%':'80%'"
                                :height="detail_type == '형사'? '':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    형사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 형사절차에서 차별화된 서비스를 제공하기 위해
                                    최적의 시스템을 갖춘 법인입니다. 
                                </p>

                                <div
                                    v-if="detail_type == '형사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        법무법인 유승의 변호사들은 체포 및 압수 단계의 법률대응부터 구속영장 발부에 대한
                                        대응, 구속적부심사, 보석 및 공판단계의 대응까지 빈틈없는 업무능력을 갖추고 있습니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        특히 체포 후 구속영장 발부 및 공소장 제출까지의 약 한달 가량의 시간동안 빠르게 진행되는 형사절차에서
                                        의뢰인이 적절히 대응하지 못하여 억울한 결과를 받아 드는 일이 없도록 법무법인 유승은 신속하고
                                        적절한 방식으로 의뢰인의 곁을 지키고 변호를 이어나가고 있습니다.
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 형사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0 text-caption">- 경제범죄, 유사수신, 증권 및 암호화폐 등과 관련된<br/>&nbsp;&nbsp;형사 변호 및 자문</p>
                                    <p class="mb-0 text-caption">- 재건축 재개발 조합 구성과 관련하여 발생하는<br/>&nbsp;&nbsp;형사분쟁에 대한 변호 및 자문</p>
                                    <p class="mb-0 text-caption">- 각종 형사사건의 고소대리</p>
                                    <p class="mb-0 text-caption">- 체포 구속에 대한 대응<br/>&nbsp;&nbsp;(영장실질심사, 구속적부심, 보석 등)</p>
                                    <p class="mb-0 text-caption">- 교통사고, 음주운전 등 운전 중 발생할 수 있는<br/>&nbsp;&nbsp;형사절차에 대한 변호 및 자문</p>
                                    <p class="mb-0 text-caption">- 성범죄(고소대리, 합의대리, 성범죄 사건에<br/>&nbsp;&nbsp;대한 변호 및 자문)</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '형사'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '형사'" color="primary" class="pb-3px">mdi-chevron-down</v-icon>
                                        <v-icon v-if="detail_type == '형사'" color="primary" class="pb-3px">mdi-chevron-up</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>

                        <v-sheet
                            v-if="detail_type == '상속/가사' || detail_type == ''"
                            :width="detail_type == '상속/가사'? '':''"
                            :height="detail_type == '상속/가사'? '':'460'"
                            style="box-shadow:6px 6px 4px 2px #eee; cursor:pointer; position:relative;"
                            class="background4 mb-6 pb-10"
                            data-aos="fade-up" 
                            data-aos-delay="900" 
                            data-aos-easing="ease"
                            @click="toggleDetail('상속/가사')"
                        >
                            <v-sheet
                                :width="detail_type == '상속/가사'? '94%':'80%'"
                                :height="detail_type == '상속/가사'? '':'400'"
                                style="background:rgba(255,255,255,0.7)"
                                class="pa-4 pt-6"
                            >
                                <p
                                    class="text-h5 font-weight-bold mb-3 primary--text"
                                >
                                    상속/가사
                                </p>
                                <v-divider></v-divider>
                                <p
                                    class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                >
                                    법무법인 유승은 피상속인의 사망으로 인하여 발생할 수 있는
                                    상속관계에 대해 구체적인 해결방안을 찾고 의뢰인이
                                    자신의 상속분을 적절히 찾을 수 있도록 최선의 노력을 다하고 있습니다.
                                </p>

                                <div
                                    v-if="detail_type == '상속/가사'"
                                >
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        특히 상속과 관련하여 유언단계에서부터 상속이 발생하는 때까지 빈틈없는 자문을 진행하여
                                        상속으로 인한 법률분쟁을 미연에 방지하는 역할도 수행하고 있습니다. 
                                    </p>
                                    <p
                                        class="text-body-2 mt-4 pr-3 grey--text text--darken-3"
                                    >
                                        가사분쟁과 관련하여는 협의이혼, 재판상이혼, 위자료청구 및 재산분할, 양육권 등의 법률분쟁들을
                                        각 분야의 특징에 맞는 대응으로 의뢰인이 원하시는 최적의 결과를 만들어 내고 있습니다. 
                                    </p>

                                    <p
                                        class="text-subtitle-1 font-weight-bold mb-2"
                                    >
                                        주요서비스
                                    </p>
                                    <p
                                        class="text-body-2 grey--text text--darken-3"
                                    >
                                        법무법인 유승은 상속 및 가사 분야와 관련하여 다음과 같은 서비스를 제공합니다.
                                    </p>

                                    <p class="mb-0 text-caption">- 유언서 작성 및 유언집행</p>
                                    <p class="mb-0 text-caption">- 상속재산분할, 유류분반환청구 및 기여분 등<br/>&nbsp;&nbsp;상속관련 분쟁해결</p>
                                    <p class="mb-0 text-caption">- 이혼, 사실혼 해소에 따른 법률분쟁해결</p>
                                    <p class="mb-0 text-caption">- 친권, 양육권소송 및 양육비청구소송</p>
                                    <p class="mb-0 text-caption">- 친생자 관련 소송</p>
                                </div>

                                <div
                                    style="background:rgba(255,255,255,0.7); position:absolute; right:0; bottom:0;"
                                >
                                    <p class="mb-0 primary--text font-weight-bold pl-3 pr-2 pt-1">
                                        {{detail_type == '상속/가사'? '접기':'더보기'}}
                                        <v-icon v-if="detail_type != '상속/가사'" color="primary" class="pb-3px">mdi-chevron-down</v-icon>
                                        <v-icon v-if="detail_type == '상속/가사'" color="primary" class="pb-3px">mdi-chevron-up</v-icon>
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>
                    </div>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import LawBanner from '@/components/LawBanner'

export default {
    components: {
        LawBanner,
    },

    data: () => ({
        detail_type: ""
    }),

    mounted(){
        if(this.$route.query.detail_type){
            this.detail_type = this.$route.query.detail_type
        }
    },

    methods: {
        toggleDetail(type){
            if(this.detail_type == ""){
                this.detail_type = type
            }
            else if(this.detail_type == type){
                this.detail_type = ""
            }
            else{
                this.detail_type = type
            }
        }
    }
}
</script>
<style scoped>
.background1 {
    background: url('~@/assets/law/1.jpg');
    background-size: cover;
}

.background2 {
    background: url('~@/assets/law/2.jpg');
    background-size: cover;
}

.background3 {
    background: url('~@/assets/law/3.jpg');
    background-size: cover;
}

.background4 {
    background: url('~@/assets/law/4.jpg');
    background-size: cover;
}
</style>