<template>
    <div>
        <!-- 배너 -->
        <IntroduceBanner />

        <!-- 본문 -->
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
            style="background:#f4f4f4;"
        >
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 980:'100%'"
                class="mx-auto"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-16':'pa-4 pb-4'"
                style="background:#f4f4f4;"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    인사말
                </p>
                <v-divider></v-divider>
                <v-sheet
                    :class="!$vuetify.breakpoint.mobile? 'pt-12':'pt-6'"
                    :height="!$vuetify.breakpoint.mobile? '':''"
                    style="background:#f4f4f4;"
                >
                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? 640:'100%'"
                        style="background:#f4f4f4;"
                    >
                        <p
                            class="mb-8 text-h4 font-weight-bold secondary--text"
                            :class="!$vuetify.breakpoint.mobile? 'text-h4':'text-h6'"
                        >
                            안녕하세요
                        </p>

                        <p
                            :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1 mb-12':'text-caption mb-0'"
                        >
                            법무법인 유승입니다.<br/>
                            저희 법무법인을 찾아주신 여러분께 진심으로 감사드립니다.<br/>
                            <br/>

                            저희 법무법인은 부동산,민사,형사,상속/가사 소송을 중심으로,<br/>
                            고객님의 권리를 보호하고 법적 문제를 해결하기 위해<br/>
                            최선을 해결책을 제시하고 있습니다.<br/>
                            <br/>

                            법무법인 유승은 고객님의 신뢰에 보답하고, 처하신 어려움을 함께<br v-if="!$vuetify.breakpoint.mobile" />
                            나누며 문제를 해결해 나가고자 합니다.<br/>
                            <br/>

                            누구에게나 법적 문제는 복잡하고 어려울 수 있습니다.<br/>
                            <br/>

                            하지만 저희 법무법인의 전문 변호사들은<br/>
                            깊이 있는 지식과 풍부한 경험을 바탕으로,<br/>
                            각 분야에서 최적의 해결책을 제공해 드릴 것입니다.<br/>
                            <br/>

                            무엇보다 소중한 의뢰인의 권리를 지키고,<br/>
                            정의를 실현하는 데 앞장서겠습니다.<br/>
                            <br/>

                            법무법인 유승에 대한 더 많은 정보와 상담이 필요하시다면<br/>
                            언제든지 유선연락 혹은 방문해주시기 바랍니다.<br/>
                            <br/>

                            법무법인 유승은 고객님의 법적 문제를 함께 <br v-if="$vuetify.breakpoint.mobile" />해결해 나가기 위해 언제나 열려 있습니다.<br/>
                            <br/>

                            감사합니다.
                        </p>
                    </v-sheet>
                    <v-img
                        v-if="!$vuetify.breakpoint.mobile"
                        src="@/assets/introduce/new.jpg"
                        class="mb-6"
                    />
                </v-sheet>
            </v-sheet>
            <v-img
                v-if="$vuetify.breakpoint.mobile"
                src="@/assets/introduce/new.jpg"
                class="mb-6 mx-4"
            />
        </v-sheet>
    </div>
</template>
<script>
import IntroduceBanner from '@/components/IntroduceBanner'

export default {
    components: {
        IntroduceBanner
    }
}
</script>
<style scoped>
.greetings {
    height:700px !important;
    background: url('~@/assets/introduce/new.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
}
</style>