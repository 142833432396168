<template>
    <div>
        <!-- 배너 -->
        <LawyerBanner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
            style="background:#f4f4f4;"
        >
            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 1080:'100%'"
                class="mx-auto"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
                style="background:#f4f4f4;"
            >
                <p
                    class="text-h5 font-weight-bold primary--text"
                    style="letter-spacing:1px !important;"
                >
                    구성원
                </p>
                <v-divider></v-divider>

                <!-- PC -->
                <div
                    v-if="!$vuetify.breakpoint.mobile"
                    class="mt-4"
                >
                    <!-- 이창훈 변호사 -->
                    <v-sheet
                        class="d-flex"
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer;"
                        data-aos="fade-left" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        :style="lawyer1? 'height:540px;':''"
                        @click="lawyer1 = !lawyer1"
                    >
                        <!-- 이미지 -->
                        <div
                            ref="lawyer_pc1"
                            class="pa-4"
                        >
                            <div
                                :style="!lawyer1? 'height:300px; overflow:hidden;':''"
                            >
                                <v-img
                                    width="280"
                                    src="@/assets/lawyer/4.jpg"
                                    style="background-position:0% 20% !important;"
                                    :style="lawyer1? 'height:508px;':'height:300px;'"
                                ></v-img>
                            </div>
                        </div>

                        <div
                            class="pa-6"
                            style="width:100%; position:relative;"
                        >
                            <div
                                class="d-flex justify-space-between align-end pr-2"
                            >
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    이창훈 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    lch@yuslaw.co.kr
                                </p>
                            </div>

                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="py-4"
                            >
                                <!-- <p
                                    class="primary--text"
                                >
                                    김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다.
                                </p> -->
                                <ul class="indent-list">
                                    <li>고려대 법과대학 졸업, 사법시험 합격, 사법연수원 수료</li>
                                    <li>변리사, 공인중개사, 부동산디벨로퍼(KPC), 부동산개발 전문인력(KODA)</li>
                                    <li>국회정보공개심의위원회 위원, 서울경찰청 자문위원</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer1"
                                class="py-4"
                            >
                                <ul class="indent-list">
                                    <li>시우, 유승 법률사무소 대표변호사</li>
                                    <li>(현) 법무법인 유승 대표변호사</li>
                                    <li>기업자문(KTB 네트워크, 넷시큐어테크놀러지, 석정도시개발 그룹, 장형그룹 등 80여 회사 자문)</li>
                                    <li>건설, 부동산 전문변호사(현대산업개발 자문, 15개 지역주택조합 및 재건축 조합 자문, 70개 단지 아파트 하자소송 진행)</li>
                                </ul>
                            </div>

                            <div
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:16px; right:16px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer1">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer1">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>

                    <!-- 오화섭 변호사 -->
                    <v-sheet
                        class="d-flex mt-8"
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer;"
                        data-aos="fade-right" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        :style="lawyer2? 'height:540px;':''"
                        @click="lawyer2 = !lawyer2"
                    >
                        <!-- 이미지 -->
                        <div
                            ref="lawyer_pc2"
                            class="pa-4"
                        >
                            <div
                                :style="!lawyer2? 'height:300px; overflow:hidden;':''"
                            >
                                <v-img
                                    width="280"
                                    src="@/assets/lawyer/1.jpg"
                                    style="background-position:0% 20% !important;"
                                    :style="lawyer2? 'height:508px;':'height:300px;'"
                                ></v-img>
                            </div>
                        </div>

                        <div
                            class="pa-6"
                            style="width:100%; position:relative;"
                        >
                            <div
                                class="d-flex justify-space-between align-end pr-2"
                            >
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    오화섭 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    chewolf@yuslaw.co.kr
                                </p>
                            </div>

                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="py-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list">
                                    <li>성균관대 법과대학원 수료(2001)</li>
                                    <li>제45회 사법시험 합격(2003)</li>
                                    <li>제35기 사법연수원 수료(2006)</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer2"
                                class="py-4"
                            >
                                <ul class="indent-list">
                                    <li>2012. ~ 2016. 서울회생법원 회생위원</li>
                                    <li>2021. ~ 현재 서울회생법원 개인파산관재인</li>
                                    <li>서울 구로구청 고문변호사(현)</li>
                                    <li>서울 관악구청 시민고충처리위원(현)</li>
                                    <li>주식회사 알체라 자문변호사(현)</li>
                                </ul>
                            </div>

                            <div
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:16px; right:16px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer2">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer2">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>

                    <!-- 김장천 변호사 -->
                    <v-sheet
                        class="d-flex mt-8"
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer;"
                        data-aos="fade-left" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        :style="lawyer3? 'height:540px;':''"
                        @click="lawyer3 = !lawyer3"
                    >
                        <!-- 이미지 -->
                        <div
                            ref="lawyer_pc3"
                            class="pa-4"
                        >
                            <div
                                :style="!lawyer3? 'height:300px; overflow:hidden;':''"
                            >
                                <v-img
                                    width="280"
                                    src="@/assets/lawyer/2.jpg"
                                    style="background-position:0% 20% !important;"
                                    :style="lawyer3? 'height:508px;':'height:300px;'"
                                ></v-img>
                            </div>
                        </div>

                        <div
                            class="pa-6"
                            style="width:100%; position:relative;"
                        >
                            <div
                                class="d-flex justify-space-between align-end pr-2"
                            >
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    김장천 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    lawkjc@yuslaw.co.kr
                                </p>
                            </div>
                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="py-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list">
                                    <li>경희대학교 법학과</li>
                                    <li>제7회 변호사시험 합격</li>
                                    <li>전) 삼성화재해상보험(주), DB손해보험(주) 소송대리인</li>
                                    <li>현) 대한변협 등록 형사법전문, 손해배상전문 변호사</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer3"
                                class="py-4"
                            >
                                <ul class="indent-list">
                                    <li>전) 국민권익위원회 전문상담위원</li>
                                    <li>현) 영등포구청 외부법률자문전문가</li>
                                    <li>현) 중소기업고문변호사, 서울지방변호사회 법률상담위원</li>
                                    <li>현) 대법원 국선변호인</li>
                                    <li>현) 서울 영등포구청 계약심의위원회 위원</li>
                                    <li>현) 대한변호사협회 신탁, 도산변호사회 위원</li>
                                    <li>현) 생연동지역주택조합 소송대리인</li>
                                    <li>현) 보아스사회공헌재단 의료비지원사업 심의위원</li>
                                </ul>
                            </div>

                            <div
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:16px; right:16px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer3">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer3">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>

                    <!-- 신동희 변호사 -->
                    <v-sheet
                        class="d-flex mt-8"
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer;"
                        data-aos="fade-right" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        :style="lawyer4? 'height:540px;':''"
                        @click="lawyer4 = !lawyer4"
                    >
                        <!-- 이미지 -->
                        <div
                            ref="lawyer_pc4"
                            class="pa-4"
                        >
                            <div
                                :style="!lawyer4? 'height:300px; overflow:hidden;':''"
                            >
                                <v-img
                                    width="280"
                                    src="@/assets/lawyer/3.jpg"
                                    style="background-position:0% 20% !important;"
                                    :style="lawyer4? 'height:508px;':'height:300px;'"
                                ></v-img>
                            </div>
                        </div>

                        <div
                            class="pa-6"
                            style="width:100%; position:relative;"
                        >
                            <div
                                class="d-flex justify-space-between align-end pr-2"
                            >
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    신동희 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    dhshin@yuslaw.co.kr
                                </p>
                            </div>
                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="py-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list">
                                    <li>한양대학교 법학과 졸업</li>
                                    <li>제58회 사법시험 합격</li>
                                    <li>제48기 사법연수원 수료</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer4"
                                class="py-4"
                            >
                                <ul class="indent-list">
                                    <li>환경부 폐기물 불법처리 방지 및 조치를 위한 법령개정안 마련 연구 연구원</li>
                                    <li>권역별 폐기물공공처리장 설치운영을 위한 특례법안 마련 연구 연구원</li>
                                    <li>서울시변호사회 중대재해처벌법 자문위원</li>
                                    <li>경기도 출자출연기관 운영심의위원</li>
                                    <li>경기도 민원 조정 위원회 위원</li>
                                    <li>경기평택항만공사 인사위원</li>
                                </ul>
                            </div>

                            <div
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:16px; right:16px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer4">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer4">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>
                </div>

                <!-- Mobile -->
                <div
                    v-if="$vuetify.breakpoint.mobile"
                    class="mt-4"
                >
                    <!-- 이창훈 변호사 -->
                    <div
                        ref="lawyer_mobile1"
                        style="height:12px; margin-bottom:12px;"
                    ></div>
                    <v-sheet
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer; position:relative;"
                        class="pb-16"
                        data-aos="fade-left" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        @click="lawyer1 = !lawyer1"
                    >
                        <div
                            class="pa-4"
                        >
                            <v-img
                                width="100%"
                                src="@/assets/lawyer/4.jpg"
                            ></v-img>
                        </div>

                        <div
                            class="px-5 py-2"
                            style="width:100%;"
                        >
                            <div>
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    이창훈 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    lch@yuslaw.co.kr
                                </p>
                            </div>
                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="pr-4"
                            >
                                <!-- <p
                                    class="primary--text"
                                >
                                    김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다.
                                </p> -->
                                <ul class="indent-list text-caption">
                                    <li>고려대 법과대학 졸업</li>
                                    <li>사법시험 합격</li>
                                    <li>사법연수원 수료</li>
                                    <li>변리사</li>
                                    <li>공인중개사</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer1"
                                class="pt-4 pr-4"
                            >
                                <ul class="indent-list text-caption">
                                    <li>부동산디벨로퍼(KPC)</li>
                                    <li>부동산개발 전문인력(KODA)</li>
                                    <li>국회정보공개심의위원회 위원, 서울경찰청 자문위원</li>
                                    <li>시우, 유승 법률사무소 대표변호사</li>
                                    <li>(현) 법무법인 유승 대표변호사</li>
                                    <li>기업자문(KTB 네트워크, 넷시큐어테크놀러지, 석정도시개발 그룹, 장형그룹 등 80여 회사 자문)</li>
                                    <li>건설, 부동산 전문변호사(현대산업개발 자문, 15개 지역주택조합 및 재건축 조합 자문, 70개 단지 아파트 하자소송 진행)</li>
                                </ul>
                            </div>

                            <div
                                ref="lawyer_mobile2"
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:12px; right:12px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer1">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer1">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>

                    <!-- 오화섭 변호사 -->
                    <div
                        style="height:12px; margin-bottom:12px;"
                    ></div>
                    <v-sheet
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer; position:relative;"
                        class="pb-16"
                        data-aos="fade-right" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        @click="lawyer2 = !lawyer2"
                    >
                        <div
                            class="pa-4"
                        >
                            <v-img
                               width="100%"
                                src="@/assets/lawyer/1.jpg"
                            ></v-img>
                        </div>

                        <div
                            class="px-5 py-2"
                            style="width:100%;"
                        >
                            <div>
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    오화섭 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    chewolf@yuslaw.co.kr
                                </p>
                            </div>

                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="pr-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list text-caption">
                                    <li>성균관대 법과대학원 수료(2001)</li>
                                    <li>제45회 사법시험 합격(2003)</li>
                                    <li>제35기 사법연수원 수료(2006)</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer2"
                                class="pt-4 pr-4"
                            >
                                <ul class="indent-list text-caption">
                                    <li>2012. ~ 2016. 서울회생법원 회생위원</li>
                                    <li>2021. ~ 현재 서울회생법원 개인파산관재인</li>
                                    <li>서울 구로구청 고문변호사(현)</li>
                                    <li>서울 관악구청 시민고충처리위원(현)</li>
                                    <li>주식회사 알체라 자문변호사(현)</li>
                                </ul>
                            </div>

                            <div
                                ref="lawyer_mobile3"
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:12px; right:12px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer2">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer2">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>

                    <!-- 김장천 변호사 -->
                    <div
                        style="height:12px; margin-bottom:12px;"
                    ></div>
                    <v-sheet
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer; position:relative;"
                        class="pb-16"
                        data-aos="fade-left" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        @click="lawyer3 = !lawyer3"
                    >
                        <div
                            class="pa-4"
                        >
                            <v-img
                                width="100%"
                                src="@/assets/lawyer/2.jpg"
                            ></v-img>
                        </div>

                        <div
                            class="px-5 py-2"
                            style="width:100%;"
                        >
                            <div>
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    김장천 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    lawkjc@yuslaw.co.kr
                                </p>
                            </div>

                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="pr-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list text-caption">
                                    <li>경희대학교 법학과</li>
                                    <li>제7회 변호사시험 합격</li>
                                    <li>전) 삼성화재해상보험(주), DB손해보험(주) 소송대리인</li>
                                    <li>현) 대한변협 등록 형사법전문, 손해배상전문 변호사</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer3"
                                class="pt-4 pr-4"
                            >
                                <ul class="indent-list text-caption">
                                    <li>전) 국민권익위원회 전문상담위원</li>
                                    <li>현) 영등포구청 외부법률자문전문가</li>
                                    <li>현) 중소기업고문변호사, 서울지방변호사회 법률상담위원</li>
                                    <li>현) 대법원 국선변호인</li>
                                    <li>현) 서울 영등포구청 계약심의위원회 위원</li>
                                    <li>현) 대한변호사협회 신탁, 도산변호사회 위원</li>
                                    <li>현) 생연동지역주택조합 소송대리인</li>
                                    <li>현) 보아스사회공헌재단 의료비지원사업 심의위원</li>
                                </ul>
                            </div>

                            <div
                                ref="lawyer_mobile4"
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:12px; right:12px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer3">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer3">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>


                    <!-- 신동희 변호사 -->
                    <div
                        style="height:12px; margin-bottom:12px;"
                    ></div>
                    <v-sheet
                        style="border:1px solid #eee; box-shadow:4px 4px 4px 2px #dedede; cursor:pointer; position:relative;"
                        class="pb-16"
                        data-aos="fade-right" 
                        data-aos-delay="300" 
                        data-aos-easing="ease"
                        @click="lawyer4 = !lawyer4"
                    >
                        <div
                            class="pa-4"
                        >
                            <v-img
                                width="100%"
                                src="@/assets/lawyer/3.jpg"
                            ></v-img>
                        </div>

                        <div
                            class="px-5 py-2"
                            style="width:100%;"
                        >
                            <div>
                                <p
                                    class="text-h5 font-weight-medium mb-1"
                                >
                                    신동희 변호사
                                </p>

                                <p
                                    class="ml-1 text-body-2 primary--text font-weight-medium mb-2"
                                >
                                    dhshin@yuslaw.co.kr
                                </p>
                            </div>

                            <v-divider class="mb-2"></v-divider>
                            
                            <div
                                class="pr-4"
                            >
                                <p
                                    class="primary--text"
                                >
                                    <!-- 김이박 변호사는 연세대학교 경영학과 및 연세대학교 법학전문대학원을 졸업하였고,
                                    변호사 자격을 취득한 뒤에는 김.장 법률사무소에서 인사노무(자문, 소송),
                                    중대재해/산업재해 대응, 일반 민사, 형사 사건 등 다양한 분야의 업무를 수행하였습니다. -->
                                </p>
                                <ul class="indent-list text-caption">
                                    <li>한양대학교 법학과 졸업</li>
                                    <li>제58회 사법시험 합격</li>
                                    <li>제48기 사법연수원 수료</li>
                                </ul>
                            </div>

                            <div
                                v-if="lawyer4"
                                class="pt-4 pr-4"
                            >
                                <ul class="indent-list text-caption">
                                    <li>환경부 폐기물 불법처리 방지 및 조치를 위한 법령개정안 마련 연구 연구원</li>
                                    <li>권역별 폐기물공공처리장 설치운영을 위한 특례법안 마련 연구 연구원</li>
                                    <li>서울시변호사회 중대재해처벌법 자문위원</li>
                                    <li>경기도 출자출연기관 운영심의위원</li>
                                    <li>경기도 민원 조정 위원회 위원</li>
                                    <li>경기평택항만공사 인사위원</li>
                                </ul>
                            </div>

                            <div
                                class="pa-4 d-flex justify-end"
                                style="position:absolute; bottom:12px; right:12px;"
                            >
                                <p class="mb-0 primary--text font-weight-medium">
                                    프로필 보기
                                    <v-icon v-if="!lawyer4">mdi-chevron-down</v-icon>
                                    <v-icon v-if="lawyer4">mdi-chevron-up</v-icon>
                                </p>
                            </div>
                        </div>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import LawyerBanner from '@/components/LawyerBanner'

export default {
    components: {
        LawyerBanner,
    },

    data: () => ({
        lawyer1: false,
        lawyer2: false,
        lawyer3: false,
        lawyer4: false,
    }),

    async mounted(){
        await setTimeout(() => {
            if (!this.$vuetify.breakpoint.mobile) {
                if(this.$route.query.lawyer == '1'){
                    this.lawyer1 = true
                    window.scrollTo({ top: 260, behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '2'){
                    this.lawyer2 = true
                    this.$refs.lawyer_pc2.scrollIntoView({ behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '3'){
                    this.lawyer3 = true
                    this.$refs.lawyer_pc3.scrollIntoView({ behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '4'){
                    this.lawyer4 = true
                    this.$refs.lawyer_pc4.scrollIntoView({ behavior: 'smooth' })
                }
            } else {
                if(this.$route.query.lawyer == '1'){
                    this.lawyer1 = true
                    window.scrollTo({ top: 160, behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '2'){
                    this.lawyer2 = true
                    this.$refs.lawyer_mobile2.scrollIntoView({ behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '3'){
                    this.lawyer3 = true
                    this.$refs.lawyer_mobile3.scrollIntoView({ behavior: 'smooth' })
                }
                else if(this.$route.query.lawyer == '4'){
                    this.lawyer4 = true
                    this.$refs.lawyer_mobile4.scrollIntoView({ behavior: 'smooth' })
                    
                }
            }
        }, 2000)
    }
}
</script>
<style scoped>
.indent-list {
    padding-left: 1em; /* 모든 항목에 동일한 들여쓰기 적용 */
    list-style-type: none; /* 기본 리스트 스타일 제거 */
}
.indent-list li::before {
    content: "ㆍ"; /* 항목 앞에 점 추가 */
    position: absolute;
    margin-left: -1em; /* 점을 왼쪽으로 이동 */
}
</style>