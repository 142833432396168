<template>
    <v-sheet
        width="100%"
    >
        <v-sheet
            width="100%"
            height="72"
            class="px-3 d-flex justify-space-between align-center"
            color="#151a36"
        >
            <!-- 언어선택 -->
            <v-btn
                icon
                dark
                @click="drawer = !drawer"
            >
                <v-icon size="28">mdi-menu</v-icon>
            </v-btn>

            <!-- 로고 -->
            <v-sheet 
                width="140"
                style="cursor:pointer;"
                color="transparent"
                @click="$router.push('/')"
            >
                <v-img
                    contain
                    src="@/assets/logo/white.svg"
                ></v-img>
            </v-sheet>

            <!-- 언어선택 -->
            <v-btn
                icon
                disabled
            ></v-btn>
        </v-sheet>

        <!-- 사이드바 -->
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            color="#151a36"
        >
            <!-- 로고 -->
            <div
                class="pa-5 pb-3 mt-1"
                color="#151a36"
            >
                <v-sheet 
                    width="140"
                    style="cursor:pointer;"
                    color="transparent"
                    @click="$router.push('/')"
                >
                    <v-img
                        contain
                        src="@/assets/logo/white.svg"
                    ></v-img>
                </v-sheet>
            </div>

            <!-- 목록 -->
            <v-list
                nav
                dark
                class="font-weight-medium px-2"
            >
                <v-list-item
                    to="/introduce"
                    class="px-4"
                    active-class="third white--text"
                >
                    소개
                </v-list-item>

                <v-list-group
                    v-for="item in menu"
                    :key="item.title"
                    v-model="item.active"
                    active-class="third white--text"
                    class="pl-2 mb-2"
                    style="margin-left:-8px;"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="pl-2 d-flex align-center">
                                <font class="text-subtitle-1 ">{{item.title}}</font>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:appendIcon>
                        <v-icon v-if="!item" class="">mdi-chevron-up</v-icon>
                        <v-icon v-if="item" class="">mdi-chevron-down</v-icon>
                    </template>
                    <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                        :to="child.link"
                        exact
                        class="white--text pl-6"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{child.title}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>


                <v-list-item
                    to="/location"
                    class="px-4"
                    active-class="third white--text"
                >
                    오시는길
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        drawer: false,

        menu: [
            {
                title: '업무분야',
                url: "/law",
                items: [
                    { title: '부동산', link: '/law?detail_type=부동산' },
                    { title: '민사', link: '/law?detail_type=민사' },
                    { title: '형사', link: '/law?detail_type=형사' },
                    { title: '상속/가사', link: '/law?detail_type=상속/가사' },
                ]
            },

            {
                title: '구성원',
                url: "/lawyer",
                items: [
                    { title: '이창훈', link: '/lawyer?lawyer=1' },
                    { title: '오화섭', link: '/lawyer?lawyer=2' },
                    { title: '김장천', link: '/lawyer?lawyer=3' },
                    { title: '신동희', link: '/lawyer?lawyer=4' },
                ]
            },
        ]
    })
}
</script>